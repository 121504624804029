const checkLocalStorage = () => {
  if (typeof window !== "undefined") {
    return true;
  }

  return false;
};

export const enum LocalStorageItems {
  "access_token" = "access_token",
  "refresh_token" = "refresh_token",
  "app_version" = "app_version",
  "role" = "role",
  "cardsOrder" = "cardsOrder",
  "userExperienceCardsOrder" = "userExperienceCardsOrder",
  "content_request_upload" = "content_request_upload",
  "feature_flag" = "feature_flag",
}

export type LocalStorageItem = keyof typeof LocalStorageItems;

export function setItem(name: LocalStorageItem, value: string): void;
export function setItem(name: string, value: string): void;
export function setItem(name: LocalStorageItem | string, value: string): void {
  try {
    if (checkLocalStorage()) {
      localStorage.setItem(name, value);
    } else {
      console.error("could not access localStorage: ", checkLocalStorage());
    }
  } catch (e) {
    console.error(
      "Error happened during setting item to localStorage",
      e,
      name,
      value,
    );
  }
}

export function getItem(name: LocalStorageItem): string | null;
export function getItem(name: string): string | null;
export function getItem(name: LocalStorageItem | string): string | null {
  try {
    if (checkLocalStorage()) {
      return localStorage.getItem(name);
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to localStorage",
      e,
      name,
    );
  }

  return null;
}

export const getAll = (): object | null => {
  try {
    if (checkLocalStorage()) {
      return { ...localStorage };
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to localStorage",
      e,
      name,
    );
  }

  return null;
};

export const deleteItem = (name: LocalStorageItem): void => {
  try {
    if (checkLocalStorage()) {
      localStorage.removeItem(name);
    }
  } catch (e) {
    console.error(
      "Error happened during getting item to localStorage",
      e,
      name,
    );
  }
};
