export const BASE_CONFIG: {
  base_backend_url: string;
  base_frontend_url: string;
  project_name: string;
  no_image_src: string;
  default_avatar: string;
  default_file_cover: string;
  base_url: string;
  retool_url: string;
  default_date_time_format: string;
  default_date_format: string;
  fake_path: string;
} = {
  base_backend_url:
    /*  process.env.NODE_ENV === "production"
      ? "/api"
      :  */ process.env.NEXT_PUBLIC_BACKEND_URL!,
  base_frontend_url:
    process.env.NEXT_PUBLIC_FRONTEND_URL! ?? "http://localhost:3000/",
  project_name: process.env.NEXT_PUBLIC_PROJECT_NAME ?? "OlynMedia",
  base_url: "http://localhost:3000",
  retool_url: process.env.NEXT_PUBLIC_RETOOL_URL!,
  no_image_src: "/images/no-image.png",
  default_avatar: "/images/default_avatar.svg",
  default_file_cover: "/static/images/default_cover.svg",
  default_date_time_format: "yyyy-MM-dd HH:mm:ss",
  default_date_format: "yyyy-MM-dd",
  fake_path: "C:\\fakepath\\",
};
